import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <Container fluid className="footer">
          <h3>Robert Lute - - - "May your code be clean, your bugs be few, and your deploys be smooth today!"</h3>
    </Container>
  );
}

export default Footer;
